import _ from 'lodash'

function wSpyLog(...args) {
  try {
    if (typeof window === 'object') { //eslint-disable-line lodash/prefer-lodash-typecheck
      _.invoke(window, 'parent.wSpy.log', ...args)
    } else if (typeof self === 'object' && self.wSpy) { //eslint-disable-line lodash/prefer-lodash-typecheck
      _.invoke(self, 'wSpy.log', ...args)
    }
  } catch (e) {} //eslint-disable-line no-empty
}

export {
  wSpyLog
}