import _ from 'lodash'

export default function cloneComponent(object) {
  if (!_.isObject(object) || !object) {
    return object
  }

  let newObject

  if (Array.isArray(object)) {
    newObject = []
    for (let i = 0; i < object.length; i++) {
      newObject[i] = cloneComponent(object[i])
    }
  } else {
    newObject = {}

    for (const property in object) {
      if (object.hasOwnProperty(property)) {
        newObject[property] = cloneComponent(object[property])
      }
    }
  }

  return newObject
}
